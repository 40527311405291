<!--  -->
<template>
  <div class="layout">
    <div class="header">
      <router-link to="/">
        <img src="@/assets/image/layout-title.png" alt="" class="logo" />
      </router-link>
      <div class="phone-box">
        <img src="@/assets/image/phone_blank_icon.png" class="phone" alt="" />
        <p class="text">
          <span>服务热线：</span>
          <strong>400-969-6261</strong>
        </p>
      </div>
    </div>

    <div class="banner">
      <img src="@/assets/image/brand-banner.png" alt="" class="banner-img" />
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "layout",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.layout {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 10%;
    .logo {
      width: 40%;
    }
    .phone-box {
      // flex: 1;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .phone {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
      }
      .text {
        // flex: 1;
        display: flex;
      }
    }
  }
  .banner {
    display: flex;
    .banner-img {
      width: 100%;
    }
  }
}
</style>
