<template>
  <div class="home">
    <!-- <Loading v-if="isLoading"></Loading> -->
    <LoadingV v-if="isLoading"></LoadingV>
    <div v-else class="main">
      <header>
        <section class="logo-wrap">
          <img src="@/assets/image/logo.png" alt="logo" class="logo" />
          <div class="join-phone">
            <img src="@/assets/image/phone_icon.png" alt="" class="icon" />
            <span>服务热线:</span>
            <span class="strong">400-969-6261</span>
          </div>
        </section>
        <div class="swiper-box">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="banner in banners" :key="banner.name">
              <!-- <a :href="banner.url" target="_blank" rel="noopener noreferrer"> -->
              <img
                class="swiper-image"
                :src="banner.image"
                alt="banner"
                @click="bannerClick(banner)"
              />
              <!-- </a> -->
            </swiper-slide>

            <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
            <!-- <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
      </header>
      <section class="data-wrap">
        <img src="@/assets/image/data1.png" alt="" class="img" />
        <div class="line"></div>
        <img src="@/assets/image/data2.png" alt="" class="img" />
        <div class="line"></div>
        <img src="@/assets/image/data3.png" alt="" class="img" />
        <div class="line"></div>
        <img src="@/assets/image/data4.png" alt="" class="img" />
      </section>
      <section class="about-box">
        <h2>
          <img
            src="@/assets/image/about.png"
            alt="关于我们"
            class="title-img"
          />
        </h2>
        <div class="content">
          <p class="title">
            北京一博千禧科技有限公司是服务高校校园文化建设，各类图书馆、党政机关等机构客户的高科技企业。
          </p>
          <p class="text">
            以e博在线、启航简笔画、语林鸟为品牌依托，开发出e博在线阅读服务平台、启航简笔画服务平台、语林鸟校园文化建设服务平台等拳头产品，提供PC、移动端、小程序、大屏、线上、线下等多终端、多种形式的服务，完全契合和满足机构及读者需求。<br />
            2023年，公司迁址到首都核心功能区商圈，将为广大客户提供更加主动周到的服务。
          </p>
          <div class="tips">
            <img src="@/assets/image/gff.png" alt="更丰富" class="icon" />
            <img src="@/assets/image/gws.png" alt="更完善" class="icon" />
            <img src="@/assets/image/ggx.png" alt="更个性" class="icon" />
            <img src="@/assets/image/gzy.png" alt="更专业" class="icon" />
            <img src="@/assets/image/gtj.png" alt="更贴近" class="icon" />
            <img src="@/assets/image/gcq.png" alt="更超前" class="icon" />
          </div>
        </div>
      </section>

      <section class="partner-organ-box">
        <div class="bg-img"></div>
        <h2>
          <img
            src="@/assets/image/partner_title.png"
            alt=""
            class="title-img"
          />
        </h2>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="item in organList" :key="item.name">
            <img class="swiper-image" :src="item.image" alt="banner" />
          </swiper-slide>

          <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
          <!-- <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
      </section>

      <section class="product-description">
        <h2 class="title-wrap">
          <img src="@/assets/image/product.png" alt="" class="title-img" />
        </h2>
        <nav class="nav-list">
          <div
            class="item"
            :class="{ active: item.active }"
            v-for="item in productList"
            :key="item.name"
            @click="productClick(item)"
          >
            {{ item.name }}
          </div>
        </nav>
        <div class="swiper-box">
          <swiper
            class="swiper"
            ref="productSwiperRef"
            @slideChangeTransitionStart="handleslideChangeTransitionStart"
            :options="ProductswiperOption"
          >
            <swiper-slide>
              <div class="slide-box">
                <img
                  src="@/assets/image/slide-img.png"
                  alt=""
                  class="slide-img"
                />
                <div class="slide-right-box">
                  <img
                    src="@/assets/image/ebo-logo.png"
                    alt=""
                    class="title-img"
                  />
                  <p class="sub-title">体验良好的阅读平台</p>
                  <ul>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">国内首批综合性阅读服务平台；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        国内首批“微信图书馆”概念及服务推广平台；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        资源模块：电子图书、电子期刊、电子报纸、有声资源、青青FM；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">覆盖终端：PC端、手机移动端、触摸屏等；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        特色模块：可实现对图书馆读者信息的查阅服务；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        产品定位：致力于为读者提供随时随地的阅读服务！
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="slide-box">
                <img
                  src="@/assets/image/yll_cover.png"
                  alt=""
                  class="slide-img"
                />
                <div class="slide-right-box">
                  <img
                    src="@/assets/image/yll_title.png"
                    alt=""
                    class="title-img"
                  />
                  <p class="sub-title">有特色的校园文化建设服务平台</p>
                  <ul>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">国内深度阅读服务倡导及领先品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">国内图书馆活动服务领导品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">国内社团建设服务引导品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">国内区块链电子证书践行、领导品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">图书馆读者个人价值输出、践行品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">坚持系统化阅读服务引导；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">首创信息“输入输出型”资源服务模式；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        产品定位：为读者个人素养提高服务！为图书馆服务效能提高服务！
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="slide-box">
                <img
                  src="@/assets/image/qhjbh_cover.png"
                  alt=""
                  class="slide-img"
                />
                <div class="slide-right-box">
                  <img
                    src="@/assets/image/qhjbh_title.png"
                    alt=""
                    class="title-img"
                  />
                  <p class="sub-title">有影响的亲子学习教育平台</p>
                  <ul>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">简笔画亲子教育互动学习服务平台；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">少儿简笔画学习领域领先品牌；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">致力于引导与成就少儿绘画天赋；</p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        领先的互动学习特性，寓教于学、寓教于乐；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        资源特色：图片与视频素材并举，提高认知，强化引导；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        服务模式：PC端、微信小程序、定制触摸屏等；
                      </p>
                    </li>
                    <li>
                      <img
                        src="@/assets/image/slide_icon.png"
                        alt=""
                        class="icon"
                      />
                      <p class="text">
                        产品定位：兴趣培养 认知教育 思维训练 亲子教育。
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </swiper-slide>

            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
      </section>

      <section class="brand-activity">
        <h2>
          <img src="@/assets/image/brand_activity.png" alt="" class="title" />
        </h2>
        <div class="activity-body">
          <div
            class="first-activity"
            :class="onlyClass"
            @click="navToFirstDetail"
          >
            <img
              :src="firstActivity.image"
              :alt="firstActivity.title"
              class="cover"
            />
            <div class="activity-con">
              <div class="time">
                <span class="year">{{ firstActivity.year }}</span>
                <span class="date">{{ firstActivity.date }}</span>
              </div>
              <div class="line"></div>
              <div class="content">
                <p class="content-title">
                  {{ firstActivity.title }}
                </p>
                <p class="content-text">
                  {{ firstActivity.intro }}
                </p>
              </div>
            </div>
          </div>
          <div class="list" v-if="activityList.length">
            <div
              class="list-item"
              v-for="item in activityList"
              :key="item.id"
              @click="navToDetail(item)"
            >
              <div class="time">
                <span class="year">{{ item.year }}</span>
                <span>{{ item.date }}</span>
              </div>
              <div class="item-con">
                <p class="item-title">{{ item.title }}</p>
                <p class="item-des">
                  {{ item.intro }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="activity-footer">
          <router-link to="/layout/brand-activity">查看更多>></router-link>
        </div>
      </section>

      <ContactUs></ContactUs>
      <PageFooter></PageFooter>
    </div>
    <!--  -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import HelloWorld from "@/components/HelloWorld.vue";
import ContactUs from "@/components/ContactUs";
import PageFooter from "@/components/PageFooter";
import { fetchBanner, fetchActivityList } from "@/api/api.js";
import { hasMobileDevice, tapOpen } from "@/utils/utils.js";
import Loading from "../components/Loading.vue";
import LoadingV from "@/components/LoadingV";

export default {
  name: "Home",
  components: {
    HelloWorld,
    Swiper,
    SwiperSlide,
    ContactUs,
    PageFooter,
    Loading,
    LoadingV,
  },
  data() {
    return {
      isLoading: true,
      swiperOption: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      productList: [
        {
          name: "e博在线",
          id: 0,
          active: true,
        },
        {
          name: "语林学习拓展数据库",
          id: 1,
          active: false,
        },
        {
          name: "启航简笔画",
          id: 2,
          active: false,
        },
      ],
      ProductswiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      banners: [],
      activityList: [],
      firstActivity: {},
      organList: [],
    };
  },
  computed: {
    onlyClass() {
      if (this.activityList.length > 0) {
        return "";
      } else {
        return "only-class";
      }
    },
  },
  methods: {
    bannerClick(data) {
      if (data.url) {
        tapOpen(data.url, "_blank");
      }
    },
    navToFirstDetail() {
      let routeData = this.$router.resolve({
        path: "/layout/activity-detail",
        query: {
          id: this.firstActivity.id,
        },
      });
      tapOpen(routeData.href, "_blank");

      // this.$router.push({
      //   path: "/layout/activity-detail",
      //   query: {
      //     id: this.firstActivity.id,
      //   },
      // });
    },
    navToDetail(data) {
      let routeData = this.$router.resolve({
        path: "/layout/activity-detail",
        query: {
          id: data.id,
        },
      });
      tapOpen(routeData.href, "_blank");
      // this.$router.push({
      //   path: "/layout/activity-detail",
      //   query: {
      //     id: data.id,
      //   },
      // });
    },
    handleslideChangeTransitionStart(e) {
      console.log("handleslideChangeTransitionStart", e);
      let activeIndex = this.$refs.productSwiperRef.$swiper.activeIndex;
      this.productList.forEach((item) => {
        item.active = +item.id == +activeIndex ? true : false;
      });
    },
    productClick(data) {
      console.log("data", data);
      this.$refs.productSwiperRef.$swiper.slideTo(data.id);
      this.productList.forEach((item) => {
        item.active = item.id == data.id ? true : false;
      });
      // console.log("bbb");
    },
    async getBanner() {
      try {
        let res = await fetchBanner({
          data: {
            position: 1,
          },
        });
        this.banners = res.data.data;
        console.log("res", res);
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },

    async getActivityList() {
      try {
        let res = await fetchActivityList({
          data: {
            page: 1,
          },
        });
        this.activityList = res.data.data.activity_list.slice(1);
        this.firstActivity = res.data.data.activity_list.slice(0, 1)[0];
        console.log("res", this.firstActivity);
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },

    async getOrganList() {
      try {
        let res = await fetchBanner({
          data: {
            position: 2,
          },
        });
        console.log("res", res);
        this.organList = res.data.data;
        return res;
      } catch (err) {
        console.log("err", err);
        return Promise.reject(err);
      }
    },
  },
  created() {
    if (hasMobileDevice()) {
      location.href = `https://www.yibo365.com.cn/h5`;
    }

    // hasMobileDevice()

    // this.getBanner();
    // this.getOrganList();
    // this.getActivityList();
    let promiseAll = [
      this.getBanner(),
      this.getOrganList(),
      this.getActivityList(),
    ];
    Promise.all(promiseAll).then((res) => {
      //
      this.isLoading = false;
    });
  },
};
</script>

<style scoped lang="scss">
//
.home {
  max-width: 1920px;
  margin: 0 auto;
  background-color: #f7f9fc;
  .logo-wrap {
    position: absolute;
    top: 40px;
    // width: 100%;
    right: 0;
    left: 0;
    height: 100px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 160px;
    box-sizing: border-box;
    .logo {
      width: 590px;
      cursor: pointer;
    }
    .join-phone {
      display: flex;
      align-items: center;
      color: #fff;
      .icon {
        width: 32px;
        height: 32px;
        margin: 0 10px 0 0;
      }
      .strong {
        margin: 0 0 0 8px;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .swiper-box {
    .swiper-image {
      width: 100%;
      cursor: pointer;
      // object-fit: cover;
      // height: 980px;
    }
  }
  .data-wrap {
    margin: 50px 0 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 162px;
    box-sizing: border-box;
    .img {
      width: 200px;
      object-fit: contain;

      &:nth-child(1) {
        width: calc(208px - 40px);
      }
      &:nth-child(3) {
        width: calc(287px - 40px);

        // width: 287px;
      }
      &:nth-child(5) {
        // width: 247px;
        width: calc(247px - 40px);
      }
      &:nth-child(7) {
        // width: 340px;
        width: calc(340px - 40px);
      }
    }
    .line {
      width: 1px;
      height: 55px;
      background-color: #999999;
    }
  }

  .about-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("../assets/image/about_bg.png");
    background-size: 100% 100%;
    .title-img {
      width: 249px;
    }
    .content {
      width: calc(100% - (2 * 160px));
      // height: 496px;
      min-height: 300px;
      background-image: url("../assets/image/about_box.png");
      background-size: 100% 100%;
      white-space: pre-wrap;
      box-sizing: border-box;
      padding: 100px 150px;
      // text-align: center;
      .title {
        font-weight: bold;
        font-size: 24px;
        text-align: left;
      }
      .text {
        line-height: 1.5;
        text-align: left;
        // padding: 0 100px;
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          width: 100px;
        }
      }
    }
  }

  .partner-organ-box {
    box-sizing: border-box;
    // padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .title-img {
      margin: 80px 0;
      width: 421px;
    }
    .swiper {
      // margin: 40px 0 0;
      width: 1200px;
      // width: calc(100vw - 200px);

      .swiper-image {
        width: 1200px;
        // width: calc(100vw - 200px);
        // object-fit: contain;
      }
    }
    .bg-img {
      position: absolute;
      top: 375px;
      left: 0;
      width: 100%;
      height: 1200px;
      background-image: url("../assets/image/brand_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }
  .product-description {
    position: relative;
    margin: 100px 0 0;
    z-index: 10;
    .title-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }
    .title-img {
      width: 371px;
    }
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      .item {
        cursor: pointer;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        margin: 0 20px;
        color: #666666;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #ffffff;
        box-shadow: 1px 2px 7px 0px rgba(52, 112, 255, 0.33);
        border-radius: 10px;
      }
      .active {
        color: #fff;
        border: 1px solid #ffffff;
        background: linear-gradient(0deg, #3470ff 0%, #a3beff 100%);
        box-shadow: 1px 2px 7px 0px rgba(52, 112, 255, 0.33);
        border-radius: 10px;
      }
    }
    .swiper-box {
      padding: 90px 100px;

      .slide-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .slide-img {
          width: 40%;
        }
        .slide-right-box {
          margin: 0 0 0 40px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .title {
            font-size: 28px;
          }
          .title-img {
            width: 200px;
          }
          .sub-title {
            font-size: 20px;
            font-weight: bold;
            position: relative;

            &::after {
              content: "";
              display: flex;
              width: 100%;
              height: 10px;
              position: absolute;
              left: 0;
              bottom: -15px;
              background: linear-gradient(90deg, #fff, #3470ff);
              border-radius: 5px;
            }
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              display: flex;
              align-items: center;
              margin: 15px 0 0 0;
              .icon {
                margin: 0 10px 0 0;
                width: 16px;
                height: 16px;
              }
              p {
                margin: 0 0;
              }
            }
          }
        }
      }
    }
  }

  .brand-activity {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 160px;
    background-image: url("../assets/image/activity-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .title {
      width: 265px;
    }
    .activity-body {
      margin: 40px 0 0 0;
      width: 100%;
      display: flex;
      .first-activity {
        cursor: pointer;
        width: 50%;
        border-radius: 10px;
        overflow: hidden;
        &.only-class {
          width: 50vw;
        }
        .cover {
          width: 100%;

          height: 280px;
          // object-fit: cover;
        }
        .activity-con {
          background-color: #fff;
          display: flex;
          align-items: center;
          padding: 20px 30px;
          // justify-content: center;
          .time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .year {
              font-weight: bold;
              font-size: 24px;
            }
            .date {
              display: inline-block;
              margin: 15px 0 0;
              color: #999;
            }
          }
          .line {
            width: 1px;
            height: 60px;
            background-color: #999;
            margin: 0 30px;
          }
          .content {
            .content-title {
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-height: 1.5;
            }
            .content-text {
              font-size: 14px;
              color: #666666;
              line-height: 1.5;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
      .list {
        width: 45%;
        flex: 1;
        margin: 0 0 0 5%;
        .list-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          &:first-child {
            margin: 0;
          }
          .time {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 120px;
            height: 120px;
            background-color: #95b4fe;
            border-radius: 10px;
            color: #fff;

            margin: 0 20px 0 0;

            .year {
              font-weight: bold;
              font-size: 28px;
              margin: 0 0 10px 0;
            }
          }
          .item-con {
            flex: 1;
            .item-title {
              font-weight: bold;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .item-des {
              //
              color: #666666;
              font-size: 14px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .activity-footer {
      padding: 60px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3470ff;
      position: relative;
      &::after {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, #fff, #999);
        left: 120%;
      }
      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: linear-gradient(-90deg, #fff, #999);
        right: 120%;
      }

      a {
        color: #999;
        text-decoration: none;
      }
    }
  }
  // .swiper-button-prev {
  //   background-color: #d3e0ff;
  //   color: #fff;
  //   padding: 10px 5px;
  //   border-radius: 10px;
  // }
  // .swiper-button-next {
  //   background-color: #d3e0ff;
  //   color: #fff;
  //   padding: 10px 5px;
  //   border-radius: 10px;
  // }
}
</style>
